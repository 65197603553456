<template>
  <div>
    <transition>
      <div v-if="data && data.length" v-show="$root.menuOpen" class="menu-container bg-blur">
        <transition name="scale" mode="out-in">
          <div v-show="$root.menuOpen" class="rooms-360-container">
            <router-link
              :to="{name:'Room',params:{roomSlug:`${data[currentIndexMenu]._slug}`}}"
              :event="isDragging ? '' : 'click'"
              draggable="false"
              @click.native="onEnter"
            >
              <image-scrubber :url="menuCube" @update="onUpdate"  @drag="onDrag($event)" />
            </router-link>
            <button type="button" class="controls prev" @click="onPrev">
              <Lottie :options="{animationData:arrowJson, loop:true}" />
            </button>
            <button type="button" class="controls next" @click="onNext">
              <Lottie :options="{animationData:arrowJson, loop:true}" />
            </button>
            <div class="enter-button">
              <v-fade-transition mode="out-in">
                <button-text
                  v-if="data[currentIndexMenu]._slug !== $route.params.roomSlug"
                  :to="{ name: 'Room', params: { roomSlug: `${data[currentIndexMenu]._slug}` } }"
                  draggable="false"
                  tag="router-link"
                  @click.native="onEnter"
                >
                  {{$root.translations[$language.current].selectRoom}}
                </button-text>
                <p v-else class="current-room-text">{{$root.translations[$language.current].currentRoom}}</p>
              </v-fade-transition>
            </div>
          </div>
        </transition>
        <div class="room-title">
          <div class="d-none d-md-block overlay"></div>
          <h1>{{data[currentIndexMenu][$language.current + 'Title']}}</h1>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Lottie from 'vue-lottie'
import arrowJson from '@/assets/bodymovin/arrow.json'

import ImageScrubber from '@/atoms/MenuScrubber.vue'
import RoomFrames from '@/assets/img/menu_frames_desktop_210125_v1.zip'
import RoomFramesMobile from '@/assets/img/menu_frames_mobile_210125_v1.zip'
import RoomFramesEmbed3 from '@/assets/img/menu_frames_2023_desktop_FC23_220923.zip'
import RoomFramesMobileEmbed3 from '@/assets/img/menu_frames_2023_mobile_FC23_220923.zip'
import ButtonText from '@/atoms/ButtonText.vue'

import GetMenu from '@/graphql/GetMenu.gql'

import { gsap } from 'gsap'

import { textureLoader } from '@/3d/panolens/PanoImage.vue'

const frames = {
  embedMode0: {
    desktop: RoomFrames,
    mobile: RoomFramesMobile
  },
  embedMode1: {
    desktop: RoomFrames,
    mobile: RoomFramesMobile
  },
  embedMode2: {
    desktop: RoomFrames,
    mobile: RoomFramesMobile
  },
  embedMode3: {
    desktop: RoomFramesEmbed3,
    mobile: RoomFramesMobileEmbed3
  }
}

export default {
  components: {
    ImageScrubber,
    ButtonText,
    Lottie
  },
  apollo: {
    Rooms: {
      query: GetMenu,
      variables () {
        return {
          status: 'PUBLISHED'
        }
      },
      result () {
        for (let i = 0; i < this.Rooms.items.length; i++) {
          const room = { ...this.Rooms.items[i] }
          this.data.push(room)

          let image = null

          if (this.$root.embedMode && room[`image360Embed${this.$root.embedMode}`]) {
            image = room[`image360Embed${this.$root.embedMode}`].id
          } else {
            image = room.image360.id
          }

          textureLoader.load(`/media/${image}`)
        }
        this.data.sort((a, b) => a.menuFrame - b.menuFrame)
        this.createTitleTimeline()
      }
    }
  },
  data () {
    return {
      arrowJson,
      currentIndexMenu: 0,
      data: [],
      isDragging: false
    }
  },
  mounted () {
    this.$events.on('menu-360-rotation', this.showRotation)
  },
  computed: {
    menuCube () {
      return frames[`embedMode${this.$root.embedMode}`][this.$vuetify.breakpoint.mdAndUp ? 'desktop' : 'mobile']
    }
  },
  methods: {
    showRotation () {
      setTimeout(() => {
        this.$events.emit('gotoFrame', { frame: 360, direct: false, speed: 1 })
      }, 250)
    },
    async createTitleTimeline () {
      await this.$nextTick()
      this.tl = gsap.timeline()
      gsap.set(this.$el.querySelector('.room-title'), { autoAlpha: 0 })
      this.tl.fromTo(this.$el.querySelector('.room-title'), { autoAlpha: 0 }, { autoAlpha: 1, duration: 1, delay: 0.5 })
      this.tl.fromTo(this.$el.querySelector('.room-title'), { autoAlpha: 1 }, { autoAlpha: 0, duration: 1, delay: 2 })
    },
    onUpdate (frame) {
      let ind = 0

      for (let index = 0; index < this.data.length; index++) {
        const mf1 = this.data[index].menuFrame
        const mf2 = this.data[(index + 1 + this.data.length) % this.data.length].menuFrame
        const diff = (mf2 - mf1) / 2
        const middle = mf2 === 0 ? 180 + diff / 2 : mf1 + diff
        if (frame >= middle) {
          ind = (index + 1 + this.data.length) % this.data.length
        }
      }

      if (ind !== this.currentIndexMenu) {
        this.currentIndexMenu = ind
        this.tl && this.tl.restart()
      }
    },
    onEnter () {
      if (this.isDragging) return
      if (this.$route.params.roomSlug !== this.data[this.currentIndexMenu]._slug) {
        this.$root.pushAnalyticsEvent('enter_on_menu_room', 'Menu', 'click', `${this.data[this.currentIndexMenu].enTitle}`, '')
      }
      this.$root.menuOpen = false
    },
    onOpen () {
      this.$root.menuOpen = true
      this.tl && this.tl.restart()

      for (let i = 0; i < this.data.length; i++) {
        const room = this.data[i]
        if (room._slug === this.$route.params.roomSlug) {
          this.currentIndexMenu = i
          const mf = this.data[i].menuFrame
          this.$events.emit('gotoFrame', { frame: mf, direct: true })
        }
      }
    },
    onNext () {
      let id = this.currentIndexMenu + 1
      if (id >= this.data.length) {
        id = 0
      }
      const mf = parseInt(this.data[id].menuFrame)
      this.$events.emit('gotoFrame', { frame: mf, action: 'next' })
    },
    onPrev () {
      let id = this.currentIndexMenu - 1
      if (id < 0) {
        id = this.data.length - 1
      }
      const mf = parseInt(this.data[id].menuFrame)
      this.$events.emit('gotoFrame', { frame: mf, action: 'prev' })
    },
    onDrag (e) {
      this.isDragging = e
    }
  },
  watch: {
    $route (to, from) {
      if (to.name === 'Home') {
        this.currentIndexMenu = 0
        this.$events.emit('gotoFrame', { frame: 0, direct: true })
      }
    },
    '$root.menuOpen' (state) {
      if (state) {
        this.onOpen()
        this.tl && this.tl.restart()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-menu;
  overflow: hidden;
}

.rooms-360-container {
  position: relative;
  margin: auto;
  width: 50%;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  user-select: none;

  @include breakpoint ('sm-and-down') {
    width: 100%;
  }

  button.controls {
    cursor: pointer;
    outline: none;
    width: rem(35px);
    height: rem(35px);
    position: absolute;
    z-index: 1;
    top: 50%;
    transition: opacity 0.3s;

    &.prev {
      left: 16px;
      transform: rotate(180deg);
    }

    &.next {
      right: 16px;
    }
  }

  .enter-button {
    position: absolute;
    right: 50%;
    bottom: 7%;
    transform: translate(50%, 7%);
    z-index: 1;
    outline: none;

    @include breakpoint ('sm-and-down') {
      bottom: 24%;
    }

    p {
      user-select: none;
      pointer-events: none;
    }
  }
}

.room-title {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  pointer-events: none;
  user-select: none;

  @include breakpoint ('sm-and-down') {
    width: 100%;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -25%);
  }

  h1 {
    @include breakpoint ('md-and-up') {
      font-size: 3.5vw;
    }
  }

  .overlay {
    position: absolute;
    z-index: -1;
    width: 150%;
    height: 150%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: radial-gradient(50% 50% at 50% 50%, $c-blue-90 0%, $c-blue-90 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.current-room-text {
  letter-spacing: 2.4px;
  font: 12px/15px futura-pt;
}

.scale-enter-active,
.scale-leave-active {
  transition: transform 1s, opacity 1s;
}

.scale-enter,
.scale-leave-to {
  opacity: 0;
  transform: scale(1.5);
}
</style>
